//Horse Racing Nation Theme

//Brand Colors
// Set the main theme with these 4 brand color variables.
// Then fine tune the major components below if necessary.
$brand-name: 'hrn';
$brand-primary-color: #00529b;
$brand-dark-primary-color: darken($brand-primary-color, 10%);
$brand-darker-primary-color: darken($brand-primary-color, 20%);
$brand-secondary-color: #004276;

//Brand Logos
$brand-logo-color: url(../public/images/logos/#{$brand-name}-logo-white.svg);
$brand-logo-avatar: url(../public/images/logos/#{$brand-name}-avatar.svg);
$brand-nav-logo-bkg-values: transparent $brand-logo-color no-repeat center left/auto 100%;

//Base Colors
$base-header-bkg-color: #222222;
$base-body-bkg-color: #CCCCCC;
$base-font-color: $black;
$action-color: $brand-secondary-color;
$light-action-color: lighten($action-color, 40%);
$dark-action-color: darken($action-color, 20%);
$base-header-bkg-gradient: linear-gradient(#404040, #202020);

//Typography
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Open+Sans:400,700|Oswald:300,400,700);
$heading-font-family: Oswald, sans-serif;
$secondary-font-family: "Open Sans Condensed", sans-serif;
$tertiary-font-family: "Open Sans", sans-serif;

//Tab Variables
// The Filter component and Accordion component will match
// these settings unless explicitly set below.
$tab-font-color: $white;
$tab-font-size: 18px;
$tab-font-family: $secondary-font-family;
$tab-font-weight: 700;
$tab-bkg-color: $brand-primary-color;
$tab-active-color: $base-header-bkg-color;
$tab-hover-color: $action-color;

//Filter Variables
$filter-font-color: $tab-font-color;
$filter-bkg-color: #777777;
$filter-active-color: $tab-active-color;
$filter-hover-color: $brand-dark-primary-color;

//Accordion Colors
$accordion-font-color: $tab-font-color;
$accordion-bkg-color: $tab-bkg-color;
$accordion-hover-color: $brand-dark-primary-color;
$accordion-close-color: $tab-font-color;

//Game Feed Link Colors
$game-feed-link-active-color: $brand-dark-primary-color;

//Nav Colors
$nav-mobile-link-color: $white;
$nav-desktop-link-color: $white;
$nav-mobile-bkg-color: $brand-primary-color;
$nav-mobile-menu-bkg-color: $brand-primary-color;
$nav-mobile-menu-font-color: $base-font-color;
$nav-mobile-menu-close-color: $white;
$nav-mobile-menu-item-link-color: $action-color;
$nav-desktop-link-border-color: $brand-primary-color;
$nav-link-cta-bkg-color: $brand-primary-color;
$nav-font-family: $secondary-font-family;
$nav-link-font-weight: 700;
$nav-text-transform: uppercase;
$nav-link-desktop-font-size: 14px;
$nav-item-padding: 0px;

//Brand Row Colors
$brand-row-font-color: $white;
$brand-row-bkg-color: linear-gradient(#4e6a78, $base-body-bkg-color);

//Tournament Table Colors
$tournament-table-link-color: $black;
$tournament-table-link-hover-color: $black;
$tournament-table-game-page-heading-link-color: $light-action-color;

//Game Page Colors
$leaderboard-current-user-row-color: $brand-primary-color;
$leaderboard-list-bkg-color: #333333;

//Form Colors
$form-box-shadow: inset 0 1px 3px rgba($black, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

//Lightbox Colors
$lightbox-bkg-color: rgba($black, .75);

//Notification Colors
$notice-color: $blue;
$light-notice-color: lighten($copen-blue, 38%);
$caution-color: $dark-yellow;
$light-caution-color: lighten($caution-color, 60%);
$bkg-caution-color: $yellow;
$error-color: $red;
$light-error-color: lighten($error-color, 58%);
$dark-error-color: darken($error-color, 10%);
$success-color: $lime;
$light-success-color: lighten($success-color, 55%);
$dark-success-color: darken($success-color, 15%);

//Notification Variables
$notification-icon-top: 17px !default;
$notification-subtle-icon-top: 12px !default;

//Avatar Variables
$avatar-top: 39px !default;
$avatar-tiny-top: 5px !default;
$avatar-small-top: 12px !default;
$avatar-medium-top: 25px !default;
$avatar-letter-spacing: normal;

//Hero
$hero-cta-color: $light-action-color;
$hero-button-bkg-color: $light-action-color;
